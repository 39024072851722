<template>
  <!-- Button trigger modal -->
  <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
    Launch static backdrop modal
  </button>

  <!-- Modal -->
  <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content rounded-5">
        <div class="modal-header">
          <div class="d-flex flex-wrap align-items-center gap-4">
            <input type="search" class="px-4 border-0 rounded-5" name="" id="" :placeholder="`&#xF52A;&nbsp;${$t('검색')}`" style="width: 21rem; height:4rem; background-color: #EAEAEA">
            <dot-button type="button" class="bg-dot-gray rounded-5" style="width: 10rem; height:4rem" data-bs-dismiss="modal">Cancel</dot-button>
            <dot-button type="button" class="bg-dot-orange rounded-5" style="width: 10rem; height:4rem">Select</dot-button>
          </div>
        </div>
        <div class="modal-body">
          <div>
            <p>Lines</p>
            <div class="d-flex flex-wrap gap-3 anticipated">
              <dot-radio-button name="shape" id="solid-line" :width="width" :height="height">
                <!-- No dashes nor gaps -->
                <svg width="24" height="4" viewBox="0 0 24 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <title>solid line</title>
                  <line x1="0" y1="1" x2="30" y2="1" stroke="black" stroke-width="4"/>
                </svg>
              </dot-radio-button>
              <dot-radio-button name="shape" id="dashed-line" :width="width" :height="height">
                <!-- Dashes and gaps of the same size -->
                <svg width="24" height="4" viewBox="0 0 24 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <title>dashed line</title>
                  <line x1="0" y1="3" x2="30" y2="3" stroke="black" stroke-width="4" stroke-dasharray="4"/>
                </svg>
              </dot-radio-button>
              <dot-radio-button name="shape" id="dotted-line" :width="width" :height="height">
                <svg width="24" height="4" viewBox="0 0 24 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <title>dotted line</title>
                  <!-- Dashes and gaps of different sizes -->
                  <line x1="0" y1="5" x2="30" y2="5" stroke="black" stroke-width="4" stroke-dasharray="4 1"/>
                </svg>
              </dot-radio-button>
            </div>
          </div>
          <hr>
          <div>
            <p>Numbers</p>
            <div class="d-flex flex-wrap gap-3 anticipated">
              <dot-radio-button name="shape" id="one" :width="width" :height="height">1</dot-radio-button>
              <dot-radio-button name="shape" id="two" :width="width" :height="height">2</dot-radio-button>
              <dot-radio-button name="shape" id="three" :width="width" :height="height">3</dot-radio-button>
              <dot-radio-button name="shape" id="four" :width="width" :height="height">4</dot-radio-button>
              <dot-radio-button name="shape" id="five" :width="width" :height="height">5</dot-radio-button>
              <dot-radio-button name="shape" id="six" :width="width" :height="height">6</dot-radio-button>
              <dot-radio-button name="shape" id="seven" :width="width" :height="height">7</dot-radio-button>
              <dot-radio-button name="shape" id="eight" :width="width" :height="height">8</dot-radio-button>
              <dot-radio-button name="shape" id="nine" :width="width" :height="height">9</dot-radio-button>
              <dot-radio-button name="shape" id="zero" :width="width" :height="height">0</dot-radio-button>
            </div>
          </div>
          <hr>
          <div>
            <p>Calculation</p>
            <div class="d-flex flex-wrap gap-3 anticipated">
              <dot-radio-button name="shape" id="plus" :width="width" :height="height">&plus;</dot-radio-button>
              <dot-radio-button name="shape" id="minus" :width="width" :height="height">&minus;</dot-radio-button>
              <dot-radio-button name="shape" id="times" :width="width" :height="height">&times;</dot-radio-button>
              <dot-radio-button name="shape" id="div" :width="width" :height="height">&div;</dot-radio-button>
              <dot-radio-button name="shape" id="equals" :width="width" :height="height">&equals;</dot-radio-button>
            </div>
          </div>
          <hr>
          <div>
            <p>English</p>
            <div class="d-flex flex-wrap gap-3 anticipated">
              <dot-radio-button name="shape" id="a" :width="width" :height="height">A</dot-radio-button>
              <dot-radio-button name="shape" id="b" :width="width" :height="height">B</dot-radio-button>
              <dot-radio-button name="shape" id="c" :width="width" :height="height">C</dot-radio-button>
              <dot-radio-button name="shape" id="d" :width="width" :height="height">D</dot-radio-button>
              <dot-radio-button name="shape" id="e" :width="width" :height="height">E</dot-radio-button>
              <dot-radio-button name="shape" id="f" :width="width" :height="height">F</dot-radio-button>
              <dot-radio-button name="shape" id="g" :width="width" :height="height">G</dot-radio-button>
              <dot-radio-button name="shape" id="h" :width="width" :height="height">H</dot-radio-button>
              <dot-radio-button name="shape" id="i" :width="width" :height="height">I</dot-radio-button>
              <dot-radio-button name="shape" id="j" :width="width" :height="height">J</dot-radio-button>
              <dot-radio-button name="shape" id="k" :width="width" :height="height">K</dot-radio-button>
              <dot-radio-button name="shape" id="l" :width="width" :height="height">L</dot-radio-button>
              <dot-radio-button name="shape" id="m" :width="width" :height="height">M</dot-radio-button>
              <dot-radio-button name="shape" id="n" :width="width" :height="height">N</dot-radio-button>
              <dot-radio-button name="shape" id="o" :width="width" :height="height">O</dot-radio-button>
              <dot-radio-button name="shape" id="p" :width="width" :height="height">P</dot-radio-button>
              <dot-radio-button name="shape" id="q" :width="width" :height="height">Q</dot-radio-button>
              <dot-radio-button name="shape" id="r" :width="width" :height="height">R</dot-radio-button>
              <dot-radio-button name="shape" id="s" :width="width" :height="height">S</dot-radio-button>
              <dot-radio-button name="shape" id="t" :width="width" :height="height">T</dot-radio-button>
              <dot-radio-button name="shape" id="u" :width="width" :height="height">U</dot-radio-button>
              <dot-radio-button name="shape" id="v" :width="width" :height="height">V</dot-radio-button>
              <dot-radio-button name="shape" id="w" :width="width" :height="height">W</dot-radio-button>
              <dot-radio-button name="shape" id="x" :width="width" :height="height">X</dot-radio-button>
              <dot-radio-button name="shape" id="y" :width="width" :height="height">Y</dot-radio-button>
              <dot-radio-button name="shape" id="z" :width="width" :height="height">Z</dot-radio-button>
            </div>
          </div>
          <hr>
          <div>
            <p>Shapes</p>
            <div class="d-flex flex-wrap gap-3 anticipated">
              <dot-radio-button name="shape" id="circle" :width="width" :height="height">
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <title>1 circle</title>
                  <path d="M26 14C26 20.6 20.6 26 14 26C7.4 26 2 20.6 2 14C2 7.4 7.4 2 14 2C20.6 2 26 7.4 26 14Z" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </dot-radio-button>
              <dot-radio-button name="shape" id="square" :width="width" :height="height">
                <title>1 square</title>
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.4 26H17.6C23.6 26 26 23.6 26 17.6V10.4C26 4.4 23.6 2 17.6 2H10.4C4.4 2 2 4.4 2 10.4V17.6C2 23.6 4.4 26 10.4 26Z" stroke="black" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>                </svg>
              </dot-radio-button>
              <dot-radio-button name="shape" id="triangle" :width="width" :height="height">
                <title>1 triangle</title>
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M22.6392 20L3.32555 20C2.23915 20 1.6356 18.7692 2.23915 17.9077L11.896 2.64615C12.3788 1.78462 13.5859 1.78462 14.1895 2.64615L23.8463 17.9077C24.3292 18.8923 23.6049 20 22.6392 20Z"  stroke="black" stroke-width="3" stroke-miterlimit="10" stroke-linecap="round"  stroke-linejoin="round"/>
                </svg>
              </dot-radio-button>
              <dot-radio-button name="shape" id="star" :width="width" :height="height">
                <title>1 star</title>
                <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.43903 7.20202L12.1328 2.51041C12.5171 1.84098 13.4829 1.84098 13.8672 2.51041L16.561 7.20202C16.706 7.45467 16.9541 7.6315 17.2402 7.68627L23.141 8.81563C23.9302 8.96668 24.228 9.93977 23.6584 10.5066L19.6596 14.4864C19.4425 14.7025 19.3364 15.0061 19.3716 15.3104L20.0551 21.2055C20.1456 21.9862 19.3416 22.5614 18.632 22.2236L13.4298 19.7474C13.1579 19.618 12.8421 19.618 12.5702 19.7474L7.36802 22.2236C6.65839 22.5614 5.85438 21.9862 5.94489 21.2055L6.62835 15.3104C6.66363 15.0061 6.55752 14.7025 6.34042 14.4864L2.34156 10.5066C1.77201 9.93977 2.06978 8.96668 2.85899 8.81563L8.75979 7.68627C9.04593 7.6315 9.29397 7.45467 9.43903 7.20202Z" stroke="black" stroke-width="3"/>
                </svg>
              </dot-radio-button>
            </div>
          </div>
        </div>
        <div class="modal-footer"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

const DotButton = defineAsyncComponent(() => import('@/components/atoms/DotButton.vue'))
const DotRadioButton = defineAsyncComponent(() => import('@/components/atoms/DotRadioButton.vue'))

export default {
  name: "CanvasTemplate",
  components: {DotButton, DotRadioButton},
  data() {
    return {
      width: "4.5rem",
      height: "4.5rem"
    }
  }
}
</script>

<style scoped>
.modal-header {
  border-bottom: 0;
}

.modal-footer {
  border-top: 0;
}

input[type="search"] {
  /*padding-left: 1rem;*/
}

input[type="search"] {
  font-family: "Helvetica Neue", sans-serif, bootstrap-icons;
}

input[type="search"]::placeholder {
  text-align: center;
}
</style>
